import { render, staticRenderFns } from "./SubcategoryPanel.vue?vue&type=template&id=e63cc572&scoped=true&"
import script from "./SubcategoryPanel.vue?vue&type=script&lang=ts&"
export * from "./SubcategoryPanel.vue?vue&type=script&lang=ts&"
import style0 from "./SubcategoryPanel.vue?vue&type=style&index=0&id=e63cc572&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e63cc572",
  null
  
)

export default component.exports