













































import {getNewNodePosition, NODE_SIZE} from '@/views/canvasHelpers';
import Vue from 'vue';

import NodeIcon from '../NodeIcon.vue';
import TriggerIcon from '../TriggerIcon.vue';

Vue.component('NodeIcon', NodeIcon);
Vue.component('TriggerIcon', TriggerIcon);

export default Vue.extend({
	name: 'NodeItem',
	props: [
		'active',
		'filter',
		'nodeType',
		'bordered',
	],
	data() {
		return {
			dragging: false,
			draggablePosition: {
				x: -100,
				y: -100,
			},
		};
	},
	computed: {
		shortNodeType(): string {
			return this.$locale.shortNodeType(this.nodeType.name);
		},
		isTrigger (): boolean {
			return this.nodeType.group.includes('trigger');
		},
		draggableStyle(): { top: string; left: string; } {
			return {
				top: `${this.draggablePosition.y}px`,
				left: `${this.draggablePosition.x}px`,
			};
		},
	},
	mounted() {
		/**
		 * Workaround for firefox, that doesn't attach the pageX and pageY coordinates to "ondrag" event.
		 * All browsers attach the correct page coordinates to the "dragover" event.
		 * @bug https://bugzilla.mozilla.org/show_bug.cgi?id=505521
		 */
		document.body.addEventListener("dragover", this.onDragOver);
	},
	destroyed() {
		document.body.removeEventListener("dragover", this.onDragOver);
	},
	methods: {
		onDragStart(event: DragEvent): void {
			const { pageX: x, pageY: y } = event;

			this.$emit('dragstart', event);

			if (event.dataTransfer) {
				event.dataTransfer.effectAllowed = "copy";
				event.dataTransfer.dropEffect = "copy";
				event.dataTransfer.setData('nodeTypeName', this.nodeType.name);
				event.dataTransfer.setDragImage(this.$refs.draggableDataTransfer as Element, 0, 0);
			}

			this.dragging = true;
			this.draggablePosition = { x, y };
		},
		onDragOver(event: DragEvent): void {
			if (!this.dragging || event.pageX === 0 && event.pageY === 0) {
				return;
			}

			const [x,y] = getNewNodePosition([], [event.pageX - NODE_SIZE / 2, event.pageY - NODE_SIZE / 2]);

			this.draggablePosition = { x, y };
		},
		onDragEnd(event: DragEvent): void {
			this.$emit('dragend', event);

			this.dragging = false;
			setTimeout(() => {
				this.draggablePosition = { x: -100, y: -100 };
			}, 300);
		},
	},
});
